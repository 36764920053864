// User IDs
export const ROSEN_USER_ID = 40074;
export const BETBEZE_USER_ID = 19523;
export const ROCKY_SWE_USER_ID = 41778;

// Team IDs
export const PHILLIES_TEAM_ID = 52953;

// Team BAM IDs
export const PHILLIES_BAM_ID = 143;
