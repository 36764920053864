// Some date constants
export const MOBILE_WIDTH = 991;

const SCHLITT = 19567;
export const DEVELOPERS = [SCHLITT];

const ROSEN = 40074;
const BETBEZE = 19523;

export const SCOUT_ADMINS = [ROSEN, BETBEZE];

// Page Labels
export const ADMIN = "Admin";
export const GROUP_PERMISSIONS = "Group Permissions";
export const DEFAULT_TITLE = "Rocky";
export const LOADING_TITLE = "Loading...";
export const BATTING_ADVANCE = "Batting Advance";
export const MATCHUP_MATRIX = "Matchup Matrix";
export const SIGN_CARDS = "Sign Cards";
export const LINEUPS = "Lineups";
export const DRAFT_BOARD = "Draft Board";
export const LISTS = "Lists";
export const EVAL = "Evaluation";
export const EVAL_SEARCH = "Evaluation Search";
export const EVAL_LIST = "Evaluation List";
export const ST_REPORTS = "ST Reports";
export const MERGE = "Merge Players";
export const INTL_PLAYER_LIST = "Intl Player List";
export const POSITIONING = "Positioning";
export const GAME_REPORT = "Game Report";
export const GAME_REPORTS = "Game Reports";
export const PLAYER_PLAN = "Player Manuels";
export const PLAYER_PLAN_APPROVAL = "Goal Approval";
export const PLAYER_PLAN_CALL_LOG = "Call Log";
export const PLAYER_PLAN_DRILLS = "Supporting Actions";
export const PLAYER_PLAN_METRICS = "Goals/Focus Areas";
export const PLAYER_PLAN_DRILLS_LOG = "How Log";
export const PLAYER_PLAN_RECENT_CHANGES = "Recent Changes";
export const KPI = "Historical Data";
export const ORG_LISTS = "Org Rosters";
export const POE = "Playoff Odds";
export const PLAYER_GUESSER = "Player Guesser";
export const ROVER_REPORTS = "Rover Reports";
export const ROVER_REPORT = "Rover Report";
export const PRO_PROJECTIONS_LEADERBOARD = "Pro Projections";
export const AMA_PROJECTIONS_LEADERBOARD = "Ama Projections";
export const DATA_VIZ_SANDBOX = "Sandbox";
export const PRO_MODEL_LEADERBOARD = "Prospect Value Leaderboard";
export const PLAYER_POPULATION = "Player Population";
// New
export const UMPIRE_CARD = "Umpire Card";
export const WALL_CARD = "Wall Card";
export const GAME_SUMMARY = "Game Summary";
export const LIST_SEARCH = "List Search";
export const PREF_LIST = "Preference List";
export const PLAYER_PAGE = "Player";
export const TEAM_PAGE = "Team";
export const TEAM_SEARCH = "Team Search";
export const MEDICAL_WORKBOOK = "Medical Workbook";

// TODO: Replace this constant w/ a hook powered by lk_player_roster_status
export const ROSTER_STATUS_MAP = {
	"7DC": "7 Day ML DL",
	"7CR": "7 Day ML DL Rh",
	"15D": "15 Day DL",
	"15R": "15 Day DL Rh",
	"60D": "60 Day DL",
	"60R": "60 Day DL Rh",
	"7DL": "7 Day DL",
	"7RH": "7 Day DL Rh",
	ACT: "Active",
	PND: "Act/PdCont",
	BRV: "Bereave",
	DEC: "Deceased",
	DFA: "Des For Asg",
	DIS: "Disqualified",
	DFT: "Dfted",
	FA: "Free Agent",
	INE: "Ineligible",
	MIL: "Military",
	NES: "Not Elig To Sgn",
	NTR: "Not To Rep",
	NYR: "Not Yet Rep",
	STK: "Strike",
	OPT: "Optioned",
	PTR: "Paternity",
	PAC: "Pnd Act",
	RIL: "Reins Not Act",
	REL: "Released",
	UR: "Rel from Rstr",
	RES: "Restricted",
	RRS: "RES/Assigned",
	NXT: "Sgn Nxt Yr",
	SUB: "Substitute",
	SMN: "Suspended",
	SUS: "Suspended",
	TI: "Temp Inactv",
	VOL: "Vol Ret",
	"21D": "21 Day DL",
	"21R": "21 Day DL Rh",
	"30D": "30 Day DL",
	"30R": "30 Day DL Rh",
	SUSL: "Suspended",
	SUSA: "Susp/Assigned",
	"10D": "10 Day DL",
	"10R": "10 Day DL Rh",
	SPT: "Suspended",
	"60I": "60 Day IL",
	"7CI": "7 Day ML IL",
	"7IL": "7 Day IL",
	"10I": "10 Day IL",
	R10: "10 Day IL Rh",
	R60: "60 Day IL Rh",
	R7C: "7 Day ML IL Rh",
	"7RI": "7 Day IL Rh"
};
