import { AxiosStatic, AxiosInstance } from "axios";

import axios from "_redux/_utils/_axios";
import axiosInference from "_redux/_utils/_axios_inference";
import axiosOperations from "_redux/_utils/_axios_operations";

export const SLICE_PIE = "pie";
export const SLICE_INFERENCE = "inference";
export const SLICE_OPERATIONS = "operations";

export const AXIOS_SLICES = {
	[SLICE_PIE]: axios,
	[SLICE_INFERENCE]: axiosInference,
	[SLICE_OPERATIONS]: axiosOperations
} as { [key: string]: AxiosStatic | AxiosInstance };
